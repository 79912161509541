<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">合同管理123</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
          <div class="df">
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="addModel('groupForm')"
              >新增合同</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="合同名称"
                align="left"
                show-overflow-tooltip
                prop="contractName"
                min-width="150px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="120px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="270px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="seeFun(scope.row.fileKey)"
                    >预览</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="editModel(scope.row.contractId)"
                    >编辑</el-button
                  >
                   <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="deleteContract(scope.row.contractId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="contractTitle"
      :visible.sync="dialogFormVisible"
      width="35%"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width:100%"
        :rules="rules"
      >
        <el-form-item
          label="合同名称："
          label-width="100px"
          prop="contractName"
        >
          <el-input v-model="groupForm.contractName" size="small" maxlength="255" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="签订机构：" label-width="100px" prop="compId">
          <el-select
            size="small"
            v-model="groupForm.compId"
            remote
            :remote-method="getCompanyLists"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyListPop"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签订合同：" label-width="100px" prop="fileName">
          <el-upload
            v-model="groupForm.fileName"
            v-if="!groupForm.fileName"
            class="upload-demo upload-btns"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button size="small" class="bgc-bv">选择合同</el-button>
          </el-upload>
          <span style="color:#f46173" v-else>
            {{ groupForm.fileName }}
            <a @click="reomveExl" style="color: #5bb5ff">删除</a>
          </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel()">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk('groupForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="合同预览"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      contractTitle: "新增合同",
      dialogCert: false /* 预览弹框 */,
      CompanyList: [],
      CompanyListPop: [],
      compId: "",
      groupForm: {
        contractName: "",
        compId: "",
        fileKey: "",
        fileName: "",
      },
      dialogFormVisible: false, //弹框
      paramsModel: {},
      rules: {
        contractName: [
          { required: true, message: "请输入合同名称", trigger: "blur" },
        ],
        compId: [
          { required: true, message: "请选择签订机构", trigger: "change" },
        ],
        fileName: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
      },
      subsidyStandardId: "",
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getCompanyLists(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyListPop = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyListPop = [];
      }
    },
    /*  合同上传*/
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
       if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      this.groupForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.groupForm.fileKey = result.data.fileKey;
          this.groupForm.fileURL = result.data.fileURL;
          // console.log(this.groupForm)
          this.$refs["groupForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.groupForm.fileName = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    /* 预览 */
    seeFun(fileKey) {
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(fileKey, "#pdf-cert");
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/biz/settlement/contract/page",
        params,
        pageNum,
      });
    },
    //删除
    deleteContract(contractId) {
      this.doDel({
        url: "/biz/settlement/contract/delete",
        msg: "确定删除合同吗？",
        ps: {
          type: "post",
          data: { contractId },
        },
      });
    },
    //新增合同
    addModel(formName) {
      this.dialogFormVisible = true;
      this.contractId = '';
      this.contractTitle = "新增合同";
      this.groupForm = {
        contractName: "",
        compId: "",
        fileKey: "",
        fileName: "",
      };
      this.$nextTick(() => {
        this.$refs["groupForm"].clearValidate("compId");
        this.$refs["groupForm"].clearValidate("contractName");
      });
    },
    editModel(contractId) {
      this.dialogFormVisible = true;
      this.contractTitle = "修改合同";
      this.contractId = contractId;
      this.getDetail(contractId)
    },
    getDetail(contractId) {
      this.$post('/biz/settlement/contract/id',{contractId}).then(ret =>{
        console.log(ret);
        let retData = ret.data;
        this.groupForm = {
          ...retData,
        };
        this.getCompany(retData.compId);
      }).catch(err => {
          return;
      })
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyListPop = [res.data];
      });
    },
    //弹框关闭
    closeModel() {
      this.groupForm = {
        contractName: "",
        fileKey: "",
      };
      this.dialogFormVisible = false;
    },
    //弹框确定
    doOk(groupForm) {
      this.$refs[groupForm].validate((valid) => {
        console.log(this.groupForm);
        if (valid) {
          const params = {
            contractName: this.groupForm.contractName,
            fileKey: this.groupForm.fileKey,
            compId: this.groupForm.compId,
            fileName: this.groupForm.fileName,
          };

          if (this.contractId) {
            params.contractId = this.contractId;
          }
          this.$post(this.contractId ?"/biz/settlement/contract/modify": "/biz/settlement/contract/insert" , params).then(
            ret => {
              this.closeModel();
              this.getData();
            }
          );
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
           40+ 0.675 * 16 + 1 
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if(route.name !='evaluate/contractManagement') return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);

          this.params = {};
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
